import React, { Component } from 'react'
import { Container } from 'react-bootstrap'

export default class NotFound extends Component {
    render() {
        return (
            
            <Container className="Center" style={{marginTop:"35vh"}}>
                <h1>404</h1>
                <h2> الصفحة غير موجودة</h2>
                
            </Container>
        )
    }
}
