import React, { Component } from 'react'
import { Container } from 'react-bootstrap';
import adsBack from '../images/pixel_background.png';
import $ from 'jquery';


export default class Home extends Component {
    componentDidMount() {
        document.title = 'PixelAdz';
      }
      
    render() {

      
        window.onload = function () {

 if ($(window).width() < 999) {
            var ImageMap = function (map) {
                    var n,
                        areas = map.getElementsByTagName('area'),
                        len = areas.length,
                        coords = [],
                        previousWidth = 1000;
                    for (n = 0; n < len; n++) {
                        coords[n] = areas[n].coords.split(',');
                    }
                    this.resize = function () {
                        var n, m, clen,
                            x = document.body.clientWidth / previousWidth;
                        for (n = 0; n < len; n++) {
                            clen = coords[n].length;
                            for (m = 0; m < clen; m++) {
                                coords[n][m] *= x;
                            }
                            areas[n].coords = coords[n].join(',');
                        }
                        previousWidth = document.body.clientWidth;
                        return true;
                    };
                    window.onresize = this.resize;
                },
                imageMap = new ImageMap(document.getElementById('mapID'));
            imageMap.resize();
        }

        }


        function backImage(){ 
        
            return (<img id="Back" src={adsBack} alt="AdsBack" useMap="#maping" />)
           }
        return (
            <div>
            
            
                {backImage()}
                <Container>
                    <p className="Note Center">الموقع غير مسؤول عن الروابط الخارجية</p> 
                </Container>
               

                <map id="mapID" name="maping">

  <area shape="rect" coords="450,450,550,550" alt="PixelAdz" title="امتلك مساحتك الاعلانية في احدث منصة الكترونية بحجم البكسل" href="http://www.instagram.com/PixelAdz" data-toggle="tooltip" />
 
  </map>

            </div>
        )
    }
}
