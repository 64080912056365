import React, { Component } from 'react'
import { Jumbotron,Container } from 'react-bootstrap';
import instaLogo from '../images/glyph_logo.png'
import twitterLogo from '../images/Twitter_logo.svg'
import emailLogo from '../images/email_logo.png'



export default class ContactUs extends Component {
    componentDidMount() {
        document.title = 'PixelAdz:ContactUs';
      }
    render() {
        return (
            <div>
           
                <Jumbotron fluid id="jumb">
  <Container>
    <p className="Center">: تواصل معنا على</p>
    <p >
      <img src={instaLogo} alt="instagram Logo" width="25vh" height="25vh"/> <a href="https://www.instagram.com/PixelAdz">@PixelAdz</a>
    </p>
    <p >
    <img src={twitterLogo} alt="twitter Logo" width="25vh" height="25vh"/><a href="https://www.twitter.com/PixelAdz"> @PixelAdz</a>
    </p>
    <p >
    <img src={emailLogo} alt="email Logo" width="25vh" height="25vh"/><a href="mailto:PixelAdz@hotmail.com"> PixelAdz@hotmail.com</a>
    </p>
  </Container>
</Jumbotron>
            </div>
        )
    }
}
