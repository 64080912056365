import React, { Component } from 'react'
import { Container } from 'react-bootstrap';

export default class AboutUs extends Component {
    componentDidMount() {
        document.title = 'PixelAdz:Idea';
      }
    render() {
        
        return (
            <Container className="Center" style={{marginTop:"10vh"}}>
                <h1>الفكرة</h1>
                <p>الموقع عبارة عن مساحة إعلانية مكونة من مليون بكسل في صفحة واحدة</p>
                <p>يشتري العميل مساحة لوضع شعار او صورة ويمكن ربطها بموقع الكتروني او حساب في مواقع التواصل</p>
                <h4 style={{color:"white", fontWeight:"800"}}>مدة الإعلان لاتقل عن 5 سنوات</h4>
            </Container>
        )
    }
}
