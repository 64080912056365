import React, { Component } from 'react'
import { Navbar,Nav } from 'react-bootstrap';
import Pricing from '../Components/Pricing';
import ContactUs from '../Components/ContactUs';
import Home from '../Components/Home';
import logo from '../images/pixel_logo_6.png';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import NotFound from './NotFound';
import AboutUs from './AboutUs';


export default class NavigationBar extends Component {
  
    render() {
      
        return (
          <Router>  
        
{/* expand="lg" */}
    <Navbar bg="dark" variant="dark"  >
    <Nav.Link href="/"> <img src={logo} alt="PixelAdz Logo" width="40px" height="40px" style={{marginRight:"0.5vh",marginBottom:"0vh",paddingLeft:"0vh"}} /></Nav.Link>
    
    {/* <Navbar.Brand style={{fontWeight:"800",fontSize:"3vh"}} href="/">PixelAdz</Navbar.Brand> */}
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="/"> <strong>الرئيسية</strong></Nav.Link>
      <Nav.Link href="/Idea"> <strong>الفكرة </strong></Nav.Link>
      <Nav.Link href="/Pricing"> <strong>الاسعار</strong></Nav.Link>
      <Nav.Link href="/ContactUs"> <strong>التواصل</strong></Nav.Link>
      
    </Nav>
  </Navbar.Collapse>
  </Navbar>


      <Switch>
      
          <Route exact path="/ContactUs">
            <ContactUs/>
          </Route>
          <Route exact path="/Pricing">
            <Pricing/>
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/Idea">
            <AboutUs/>
          </Route>
          <Route path="*">
            <NotFound/>
          </Route>
        </Switch>
        </Router>
            
        )
    }
}
