import { trim } from 'jquery';
import React, { Component } from 'react'
import { Jumbotron,Container,Button } from 'react-bootstrap';
import Swal from 'sweetalert2'



export default class Pricing extends Component {
    
    componentDidMount() {
        document.title = 'PixelAdz:Pricing';
       
      }
      

    render() {
    
var TWidth = 0
var THeight = 0
function DimensionSize(){
     TWidth = document.getElementById("iwidth");
     THeight = document.getElementById("iheight");
  
        var TWidthToInt =parseInt(trim(TWidth.value.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) { return d.charCodeAt(0) - 1632; })
        .replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) { return d.charCodeAt(0) - 1776; })))

        var THeightToInt =parseInt(trim(THeight.value.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) { return d.charCodeAt(0) - 1632; })
        .replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) { return d.charCodeAt(0) - 1776; })))

    if ((TWidthToInt && THeightToInt) && (TWidthToInt%10===0 && THeightToInt%10===0)&&(TWidthToInt<=1000 && THeightToInt<=1000)) {
      
    var cost =  TWidthToInt*THeightToInt
    document.getElementById("DSize").innerHTML=`<p>السعر= ${cost} ريال</p>`
    document.getElementById("TImage").style.width = TWidthToInt+"px"
    document.getElementById("TImage").style.height = THeightToInt+"px"
    document.getElementById("SizeTxt").innerHTML = "<p>الحجم</p>"
    } else {
        Swal.fire('ادخل ارقام من مضاعفات العشرة وبحد اقصى 1000 ')
    }
}



        return (
            <div>
          
            <img id="im"  alt=""/>
              <Jumbotron fluid id="jumb">
              
  <Container className="Center">
  <p className="Center">١ بكسل = ١ ريال </p>
    <p className="BNote">
     تبدأ مساحة الإعلان من 100 بكسل بقيمة 100 ريال سعودي ، او مضاعفاتها
    </p>
    <p className="BNote">
     ويمكننا تصغير الصورة الخاصة بكم لتتناسب مع المقاس المطلوب
    </p>
    <small className="Note">ملاحظة: تقاس المساحة حسب شاشة الكمبيوتر</small>
  </Container>
</Jumbotron>
          



<div className="grid-container">
  <div className="Arabic" >
  
 

  <Container  >
            <p >: السعر حسب مقاس الصورة</p>
            <br/>
            
  
                <form  className="Center" >


                <input className="Dynamic" autoComplete="off"  id="iwidth" name="iwidth" placeholder="العرض بالبكسل" type="text" pattern="\d*"/>
                <input className="Dynamic" autoComplete="off" id="iheight" name="iheight" placeholder="الطول بالبكسل" type="text" pattern="\d*"/>

                <br/>
                <br/>
                
               <Button variant="success" type="button"  onClick={DimensionSize}>احسب</Button>
                </form>
                </Container>


                <Jumbotron fluid id="jumb">
  <Container className="Center">
    
    <p id="DSize">
     السعر= 0 ريال
     
    </p>
    <p id="SizeTxt">
        
    </p>
  </Container>

  <div className="Center" id="TImage" style={{width:"0px", height:"0px"}}>
            {/*  for img example added later by function */}
            </div>
</Jumbotron>
  </div>

</div>
                
            </div>
        )
    }
}
