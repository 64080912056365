import React, { Component } from 'react'
import NavigationBar from "./Components/NavigationBar";
import './App.css';






export default class App extends Component {


  render() {
    return (
      <div className="App">
       
      <header className="App-header">
      <NavigationBar/>

      </header>
      
    </div>
    )
  }
}


